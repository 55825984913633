import React from 'react';
import Alert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker } from 'react-dates';
import moment from "moment";
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Slide from '@material-ui/core/Slide';
import "./styles.css";
import {GuestListBanner} from '../assets/images';
import {materialColor, listMainTextColor, underlayColor, searchBarBorderColor, borderBottomColor, brandColor, headerColor, borderColor, deleteColor, titleTextColor} from '../constants/colors';
import Snackbar from '@material-ui/core/Snackbar';
import { ScrollView, Heading, VStack, FormControl, Input, Button, HStack, Icon, Spinner, Flex, Box, Switch, Checkbox, IconButton, CloseIcon, Pressable } from "native-base";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

var permanentOption = [];

const styles = theme => ({
  paper: {
    flex: 1,
    // padding: 5
  },
  fab: {
    position: 'absolute',
    bottom: 30,
    right: 40,
    zIndex: 10,
    elevation: 10
  },
  tabBox: {
    display: 'flex',
    marginTop: 10,
  },
  loading: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    //backgroundColor: '#0000ff47',
    display:'flex'
  },
  appBar: {
    position: 'relative',
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class AddEditGuest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: 0,
      selectedIndexSegment: 0,
      segmentValues: [],
      //selectedModeSegment: '',
      selectedMode: '',
      selectedStartDate: '',
      selectedEndDate: '',
      fName: '',
      lName: '',
      notes: '',
      isNew: true,
      gid: '',
      loading: false,
      isEditableText: true,

      companyName: "",
      neverNotify: false,
      customTextbox1: "string",
      customTextbox2: "string",
      commercial: false,
      customCheckbox1: false,
      customSegment1: '',
      guestDeviceNumberOfUses: 0,
      switchVal: false,

      minDate: new Date(),
      maxDate: new Date(),
      open: false,
      openEmail: false,
      url: '',
      message: '',
      email: '',
      messageType: '',
      alertOpen: false
    };
  }

  goBack = () => {
    this.props.history.goBack();
  };

  handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({alertOpen: false});
  };

  showAlert = (message, severity) => {
    //console.log('showing alert');
    const { classes } = this.props;
    return(
      <div className={classes.fab} style={{position: 'fixed'}}>
        <Snackbar open={this.state.alertOpen} autoHideDuration={3000} onClose={this.handleCloseAlert}>
          <Alert
            variant="filled"
            severity={severity}
          >
            {message}
          </Alert>
        </Snackbar>
      </div>
    )
  }

  setMode(mode) {
    if(mode === 'Temporary'){
      this.setState({selectedMode: 'temporary'});
    }else{
      this.setState({selectedMode: 'permanent'});
    }
    //this.setState({selectedMode: mode});
    //console.log('selectedMode: ',mode);
  }

  setModeSegment(mode) {
    this.setState({customSegment1: mode});
  }

  onSubmit=()=> {
    const {
      selectedMode,
      selectedStartDate,
      selectedEndDate,
      fName,
      lName,
      companyName,
      notes,
      isNew,
      gid,
      neverNotify,
      customTextbox1,
      customTextbox2,
      commercial,
      customCheckbox1,
      customSegment1,
      guestDeviceNumberOfUses,
      switchVal,
      startDate,
      endDate
    } = this.state;

    // console.log('selectedStartDate: ',selectedStartDate);
    // console.log('startDate: ',startDate);

   var sDate = startDate;
   var eDate = endDate;

    if (lName == null || lName === '')
    {
      this.setState({alertOpen: true, message: 'Last Name is required', messageType: 'error'});
      return;
    }
    if (fName == null)
    {
      // this.setState({alertOpen: true, message: 'First Name is required', messageType: 'error'});
      // return;
    }
    if(selectedMode === 'temporary')
    {
      if (isNew) {

        if (sDate == null && eDate == null)
        {
          this.setState({alertOpen: true, message: 'Please select a date range', messageType: 'error'});
          return;
        }
        else
        {
          //sDate =  selectedStartDate;
          //eDate = selectedEndDate;
        }
      }
      else
      {
        if ((sDate == null) && (eDate == null))
        {
          if ((selectedStartDate === '') && (selectedEndDate === ''))
          {
            this.setState({alertOpen: true, message: 'Please select a date range', messageType: 'error'});
            return;
          }
          else
          {
            sDate = selectedStartDate;
            eDate = selectedEndDate;
          }
        }
        else
        {
          if (sDate==null)
          {
            sDate = selectedStartDate;
          }
          if (eDate==null)
          {
            eDate = selectedEndDate;
          }
        }
      }

      let expectedDateRange = 0;
      if((this.getValue('GuestListCommercialCheckbox')) && (commercial)){
        expectedDateRange = this.getValue('GuestListCommercialMaximumDuration');
      } else {
        expectedDateRange = this.getValue('GuestListMaximumTemporaryDays');
      }
      var start=moment(selectedStartDate);
      var end=moment(selectedEndDate);
      let dateRange = end.diff(start, 'days');
      // console.log('expectedDateRange: '+expectedDateRange+"   dateRange: "+dateRange);
      if(dateRange >= expectedDateRange){
        this.setState({alertOpen: true, message: 'Date should not be more than '+expectedDateRange+' days from start date', messageType: 'error'});
        return;
      }
    }
    let saveData = {
      last_Name: lName,
      first_Name: fName,
      // start_Date:
      //   selectedMode === 'permanent'
      //     ? null
      //     : apiFormatDate(selectedStartDate) + 'T00:00:00',
      // end_Date:
      //   selectedMode === 'permanent'
      //     ? null
      //     : apiFormatDate(selectedEndDate) + 'T00:00:00',
      start_Date:
        selectedMode === 'permanent'
          ? null
          : new Date(sDate),
      end_Date:
        selectedMode === 'permanent'
          ? null
          : new Date(eDate),
      notes: notes,
      companyName: companyName,
      neverNotify: neverNotify,
      customTextbox1: customTextbox1,
      customTextbox2: customTextbox2,
      commercial: commercial,
      customCheckbox1: customCheckbox1,
      customSegment1: customSegment1,
      guestDeviceNumberOfUses: guestDeviceNumberOfUses,
      //switchVal: switchVal
    };

    if (!switchVal)
    {
      saveData.guestDeviceNumberOfUses = 0;
    }
    if (!isNew) {
      saveData.guestID = gid;
    }
    this.saveEdit(saveData);
  }

  componentDidMount() {
    let segmentValue = this.getValue('GuestListCustomGuestDropdown1Items');
    // console.log('segmentValue: ',segmentValue);
    if (segmentValue != null)
    {
    var a1 = new Array();
    a1=segmentValue.split(";");
    this.setState({segmentValues: a1, customSegment1: a1[0]});
    // console.log('a1: ',a1);
    }
    this.getInitials();
    this.getGuest();
  }

  getGuest = async () => {
    const {getSpecificGuest} = this.props;
    const allData = this.props.history.location.state.data;

    // let segmentValue = this.getValue('GuestListCustomGuestDropdown1Items');
    // //console.log('segmentValue: ',segmentValue);
    // if (segmentValue != null)
    // {
    // var a1 = new Array();
    // a1=segmentValue.split(";");
    // this.setState({segmentValues: a1, customSegment1: a1[0]});
    // //console.log('a1: ',a1);
    // }

    if(allData){
      let guestData = await getSpecificGuest(allData.guestID);
      // console.log('guestData: ',guestData);
      let isEditable = this.editableText();
      if (guestData.success === false) {
        alert("Guest not found!!");
        return;
      }
      //console.log('@AddEditGuest: ',isEditable);
      this.setState({
        fName: guestData.data[0].first_Name,
        lName: guestData.data[0].last_Name,
        companyName: guestData.data[0].company,
        notes: guestData.data[0].notes,
        isNew: false,
        gid: guestData.data[0].guestID,
        startDate: guestData.data[0].start_Date,
        endDate: guestData.data[0].end_Date,
        selectedStartDate: guestData.data[0].start_Date,
        selectedEndDate: guestData.data[0].end_Date,
        selectedMode: guestData.data[0].start_Date || guestData.data[0].end_Date ? 'temporary' : 'permanent',
        selectedIndex: guestData.data[0].start_Date || guestData.data[0].end_Date ? 0 : 1,
        isEditableText: isEditable,
        neverNotify: guestData.data[0].neverNotify,
        customTextbox1: guestData.data[0].textTest1,
        customTextbox2: guestData.data[0].textTest2,
        commercial: guestData.data[0].commercial,
        customCheckbox1: guestData.data[0].testCheck1,
        customSegment1: guestData.data[0].customSegment1,
        guestDeviceNumberOfUses: guestData.data[0].guestDeviceNumberOfUses,
        switchVal: (guestData.data[0].guestDeviceNumberOfUses>0) ? true : false
      }, ()=> this.getInitials());
    }
  }

  getInitials = () => {

   if (this.state.commercial)
    {
      if (this.getValue('GuestListCommercialCheckbox') && this.getValue('GuestListCommercialAllowPermanent')){
        permanentOption = ["Temporary", "Permanent"];
        this.handleIndexChange(0);
      } else {
        permanentOption = ["Temporary"];
        this.handleIndexChange(0);
      }
    }
    else
    {
      if(this.getValue('GuestListAllowPermanentGuests')){
        permanentOption = ["Temporary", "Permanent"];
        // console.log('selectedStartDate:',this.state.selectedStartDate);
        if (this.state!= null)
        {
          if (((this.state.selectedStartDate === '') || (this.state.selectedStartDate == null)) && ((this.state.selectedEndDate === '') || (this.state.selectedEndDate == null)))
          {
            this.handleIndexChange(1);
          }
          else
          {
            this.handleIndexChange(0);
          }
        }
        else
        {
          this.handleIndexChange(0);
        }
      } else {
        permanentOption = ["Temporary"];
        this.handleIndexChange(0);
      }
    }

    this.getInitialDates();

    let segmentValues = this.state.segmentValues;
    for(let x in segmentValues){
      if(segmentValues[x] === this.state.customSegment1){
        //console.log('segmentedIndex: ',x);
        this.handleIndexChangeSegment(parseInt(x));
      }
    }
  }

  getInitialDates = () => {
    let today = new Date();
    let md = new Date();
    //let today_date = today.getFullYear() +'-'+ (today.getMonth() + 1) +'-'+today.getDate();
    if(this.state.selectedStartDate !== ''){
      let td = this.state.selectedStartDate;
      moment.locale('en');
      let d = moment(td, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY-MM-DD');
      today = new Date(d);
    }
    //if(this.state.selectedStartDate == ''){
      md.setDate(today.getDate()+365);

    this.setState({minDate: today, maxDate: md});
    //console.log('maxDate: '+md+' today: '+today.getDate()+ ' today: '+today);
  }

  saveEdit = async savedata => {
    this.setState({loading: true});
    const thisThat = this;
    //const {selectedMode, commercial, selectedStartDate, selectedEndDate} = this.state;

      // console.log('savedata: ',savedata);

      const {editGuestCall, addGuestCall} = this.props;
      let abcd = "";
      if (savedata.guestID) {
        abcd = await editGuestCall(savedata);
        //await getAllGuest();
        //console.log('abcd: ',abcd);
        this.setState({loading: false});
        if (abcd.success) {
          thisThat.props.history.goBack();
        }
      } else {
        abcd = await addGuestCall(savedata);
        //console.log('abcd: ',abcd);
        var myobj = JSON.parse(JSON.stringify(abcd));
        // console.log('myObj: ',myobj);

        if (abcd.success === false) {
          this.setState({loading: false, alertOpen: true, message: ''+myobj.data.data.message, messageType: 'error'});
          return;
        }
        this.setState({loading: false, gid: abcd.data.newGuestID});

        //getAllGuest();
        if (thisThat.getValue('GuestListAllowBarcodeSharing')) {
          this.handleOpen();
        }
        else
        {
          thisThat.props.history.goBack();
        }
      }
  };

  qrClick = async() => {
    this.setState({loading: true});
    const {getGuestQr} = this.props;
    //debugger;
    // console.log('guestID: ', this.state.gid);
    const {response} = await getGuestQr(this.state.gid);

    if (response) {
      this.setState({loading: false});
      this.setState({
        //title: 'Qr Code',
        message: response.data.bodytext,
        url: `data:image/png;base64,${response.data.result}`,
        open: true
      });
    }
  };

  qrClickEmail = async() => {
    this.setState({loading: true});
    const {sendGuestQr} = this.props;
    //debugger;
    //console.log('guestID: ', this.state.gid);
    const {response} = await sendGuestQr(this.state.gid, this.state.email);

    if (response) {
      this.setState({loading: false});
      if(this.state.isNew){
        this.props.history.goBack();
      }
    }
  };

  getValue(setting) {
    const {loginData} = this.props;
    if (loginData) {
      const appOptions = loginData.appOptions;
      for (let x of appOptions) {
        if (x.setting === setting) {
          return x.value;
        }
      }
    }
  }

  handleIndexChange = index => {
    // console.log('hi',index);
    this.setState({
      ...this.state,
      selectedIndex: index
    });
    //new dan code below:
    if (index === 1) {
      //this.selectedStartDate == null;
      //this.selectedEndDate == null;
      this.setState({selectedStartDate: '', selectedEndDate: ''}, ()=> this.getInitialDates());
    }
    //end dan new code
    this.setMode(permanentOption[index]);
  };

  handleIndexChangeSegment = index => {
    this.setState({
      ...this.state,
      selectedIndexSegment: index
    });
    this.setModeSegment(this.state.segmentValues[index])
  };

  editableText = () => {
    let allow = this.getValue('GuestListAllowRenaming');
    //console.log('@AddEditGuest1: ',allow);
    return allow;
  }

  onCommercialSelected = () => {

     if (this.state.commercial)
     {
       if (this.getValue('GuestListCommercialCheckbox') && this.getValue('GuestListCommercialAllowPermanent')){
         permanentOption = ["Temporary", "Permanent"];
         //this.selectedStartDate = null;
         //this.selectedEndDate = null;
         this.handleIndexChange(0);
       } else {
         permanentOption = ["Temporary"];
         //this.selectedStartDate = null;
         //this.selectedEndDate = null;
         this.handleIndexChange(0);
       }
     }
     else
     {
       if(this.getValue('GuestListAllowPermanentGuests')){
         permanentOption = ["Temporary", "Permanent"];
         //this.selectedStartDate = null;
         //this.selectedEndDate = null;
         this.handleIndexChange(0);
       } else {
         permanentOption = ["Temporary"];
         //this.selectedStartDate = null;
         //this.selectedEndDate = null;
         this.handleIndexChange(0);
       }
      //console.log('permanentOption: ',permanentOption);
    }
  }

  onPressMinus = (e) => {
    const { guestDeviceNumberOfUses } = this.state;
    const minusNumber = guestDeviceNumberOfUses - 1;

    if (guestDeviceNumberOfUses === 0) {
      return;
    }

    return this.setState({ guestDeviceNumberOfUses: minusNumber });
  }

  onPressPlus = (e) => {
    const { guestDeviceNumberOfUses } = this.state;
    const plusNumber = guestDeviceNumberOfUses + 1;

    if (guestDeviceNumberOfUses === 10) {
      return;
    }

    return this.setState({ guestDeviceNumberOfUses: plusNumber });
  }

  handleSwitchChange = (event) => {
    let val = 0;
    if (event) {
      val = 1;
    } else {
      val = 0;
    }
    // this.setState({[event.target.name]: event.target.checked, showExpired: val});
    this.setState({switchVal: event, showExpired: val });
  };

  handleChange = (e) => {
    //console.log(e.target.name);
    //console.log(e.target.value);
    this.setState(Object.assign(this.state, { [e.target.name]: e.target.value }))
  }

  createTabs = () => {
    const { selectedIndex } = this.state;
    let {classes} = this.props;
    //console.log("hello: ",permanentOption);
    const allTabs = permanentOption.map((tab,index) => {
      return (
        <li>
          <Button
            key={index}
            style={{backgroundColor: selectedIndex === index ? materialColor : "white", borderRadius:0, width: "100%", marginRight: 100}}
            //color={selectedIndex === index ? "primary" : "#1122ff"}
            variant={selectedIndex === index ? "solid" : "outline"}
            // className={selectedIndex === index ? "tab active" : "tab"}
            onPress={() => this.handleIndexChange(index)}
          >
            {tab}
          </Button>
        </li>
      );
    });

    return <ul className={classes.tabBox}>{allTabs}</ul>;
  };

  createTabsSegment = () => {
    let { selectedIndexSegment, segmentValues  } = this.state;
    let {classes} = this.props;
    //console.log("hi: ",segmentValues);
    let allTabsSegment = segmentValues.map((tab,index) => {
      return (
        <li>
          <Button
            key={index}
            style={{backgroundColor: selectedIndexSegment === index ? materialColor : "white", borderRadius:0, width: "100%", marginRight: 100}}
            variant={selectedIndexSegment === index ? "solid" : "outline"}
            // className={selectedIndexSegment === index ? "tab active" : "tab"}
            onPress={() => this.handleIndexChangeSegment(index)}
          >
            {tab}
          </Button>
        </li>
      );
    });

    return <ul className={classes.tabBox}>{allTabsSegment}</ul>;
  };

  handleOpen = () => {
    this.qrClick();
    //this.setState({open: true});
  };

  handleClose = () => {
    this.setState({open: false});
    if(this.state.isNew){
      this.props.history.goBack();
    }
  };

  handleCloseEmail = () => {
    this.setState({openEmail: false});
    if(this.state.isNew){
      this.props.history.goBack();
    }
  };

  validate = () => {
    var reEmail = /^(?:[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+\.)*[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+@(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!\.)){0,61}[a-zA-Z0-9]?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!$)){0,61}[a-zA-Z0-9]?)|(?:\[(?:(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\]))$/;
    if (this.state.email === null || this.state.email === undefined) {
      alert("E-mail can't be Empty");
      return false;
    }
    if (!this.state.email.match(reEmail)) {
      alert("E-mail is not in good format");
      return false;
    }
    this.handleClose();
    this.qrClickEmail();
  }

  copyImage = async(url) => {
    // navigator.clipboard.writeText(''+url);
    try {
        const image = await fetch(url);
        const imageBlob = await image.blob();

        await navigator.clipboard
            .write([
                new window.ClipboardItem({
                    'image/png': imageBlob,
                })
            ]);
          } catch (error) {
            console.error(error);
        }
  }

  render() {
    let {
      selectedMode,
      selectedStartDate,
      selectedEndDate,
      fName,
      lName,
      companyName,
      notes,
      // isNew,
      // gid,
      loading,
      isEditableText,
      neverNotify,
      customTextbox1,
      customTextbox2,
      commercial,
      customCheckbox1,
      // customSegment1,
      guestDeviceNumberOfUses,
      switchVal,
      minDate,
      maxDate,
      open,
      // openEmail,
      message,
      messageType,
      url
    } = this.state;
    let {classes} = this.props;

    let edit = this.props.history.location.state.data;

    // console.log("hi1: ",selectedStartDate);
    // console.log("hi2: "+moment(selectedStartDate, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY-MM-DD'));
    // console.log("hi3: "+moment(selectedStartDate.split('T')[0], 'YYYY-MM-DD').format('YYYY-MM-DD'));
    return (
      
        <div className={classes.paper}>
          <img
            style={{width: window.innerWidth, height: 230, resizeMode: 'contain', margin: 'auto', alignSelf: 'center'}}
            src={GuestListBanner}
            alt={'GuestListBanner'}
          />
          <div style={{backgroundColor: headerColor, padding: 20, marginTop: 5, borderRadius: 10, zIndex: 10, elevation: 10, marginBottom: 20}}>
            {this.showAlert(message, messageType)}
            {/* { edit && this.getValue('GuestListAllowBarcodeSharing') && (
              <Button _light={{ bg: titleTextColor, _text: { color: "white" } }}
                _dark={{ bg: titleTextColor, _text: { color: "white" } }}
                disabled={loading}
                style={{borderRadius: 20, position: 'absolute', top: 20, right: 20}}
                onPress={this.handleOpen}>
                Share QR Code
              </Button>
            )} */}

            <Dialog disableBackdropClick disableEscapeKeyDown open={open} onClose={this.handleClose} TransitionComponent={Transition}>
              <AppBar className={classes.appBar} style={{backgroundColor: brandColor}}>
                <Toolbar>
                  <HStack space={4} alignItems="center" justifyContent="space-between" w="100%">
                    <Heading _dark={{
                      color: "white"
                    }} color="white" fontWeight="medium" size="md">
                      Share QR Code
                    </Heading>

                    <IconButton colorScheme="indigo" key={"ghost"} variant={"ghost"} icon={<CloseIcon size="5" color="white" />}
                      _hover={{
                        bg: "orange.600:alpha.20"
                      }}
                      onPress={this.handleClose} />
                  </HStack>
                </Toolbar>
              </AppBar>

              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '2%', marginRight: '3%', marginLeft: '3%'}}>
                <img
                  style={{width: window.innerWidth * (20 / 100), resizeMode: 'contain', alignSelf: 'center'}}
                  src={url}
                  alt={'qrcode'}
                />
              </div>

              <Button
                variant="link" colorScheme="secondary"
                style={{alignSelf: 'center', fontSize: 24}}
                onPress={()=> this.copyImage(''+url)}>
                Click here to copy FastAccess Pass
              </Button>

              <HStack space={3} ml="5" mr="5" mb="5" justifyContent="center">

                <FormControl w="60%">
                  <FormControl.Label>
                    E-mail to share QR Code
                  </FormControl.Label>
                  <Input
                    // variant="rounded"
                    size="lg"
                    borderColor={borderColor}
                    // style={{ color: (this.state.darkMode) ? '#ffffff' : '#000000' }}
                    onChangeText={email => this.setState({email})}
                    value={this.state.email}
                    autoCapitalize={false}
                    autoCorrect={false}
                    placeholder={'Enter E-mail to share QR Code'}
                    // placeholderTextColor={(darkMode) ? '##666' : '#C7C7CD'}
                    InputLeftElement={<IconButton icon={<Icon as={<FontAwesomeIcon icon={['fas', 'book']} size="lg"/>} />}/>}
                    // InputLeftElement={<Icon as={<MaterialIcons name="person" />} size={5} ml="2" color="muted.400" />}
                    returnKeyType='done'
                  />
                </FormControl>
                <Button _light={{ bg: materialColor, _text: { color: "white" } }}
                  _dark={{ bg: materialColor, _text: { color: "white" } }}
                  disabled={loading}
                  w="70"
                  h="35"
                  mt="8"
                  style={{borderRadius: 5}}
                  onPress={this.validate}>
                  Send
                </Button>
                <Button _light={{ bg: "#ED1C24", _text: { color: "white" } }}
                  _dark={{ bg: "#ED1C24", _text: { color: "white" } }}
                  disabled={loading}
                  w="60"
                  h="35"
                  // ml='5'
                  mt="8"
                  style={{borderRadius: 5}}
                  onPress={this.handleClose}>
                  Close
                </Button>
              </HStack>

            </Dialog>

            <VStack style={{borderWidth: 1, borderRadius: 5, borderColor: borderColor, backgroundColor: 'white', marginTop: 15}}>
              <HStack space={3} w='97%' alignItems='center' justifyContent='space-between'>
                <Heading ml={6} mt={6} _dark={{
                  color: titleTextColor
                }} color={titleTextColor} fontWeight="medium" size="md">
                  Guest Name {(isEditableText) ? '' : '\n(Your Community has disabled Guest Renaming)'}
                </Heading>
                { edit && this.getValue('GuestListAllowBarcodeSharing') && (
                  <Pressable
                    mt={6}
                    style={{borderRadius: 5, width: '15%', justifyContent: 'center', alignItems: 'center', background: 'linear-gradient(180deg, #0B7645 30%, #042B1C 90%)'}}
                    onPress={this.handleOpen}
                    disabled={loading}
                    underlayColor={underlayColor}>
                    <HStack space={3} justifyContent="space-between" alignItems="center" p="3">
                      <Icon as={<FontAwesomeIcon icon={['fas', 'share']} size="lg" color={"white"}/>}/>
                      <Heading _dark={{
                        color: "white"
                      }} color="white" fontWeight="medium" size="sm">
                        Share QR Code
                      </Heading>
                    </HStack>
                  </Pressable>
                )}
              </HStack>
              <VStack space={3}>
                <HStack space={3} m={6} w='47%' alignItems='center' justifyContent='space-between' mb={1}>
                  <FormControl>
                    {/* <FormControl.Label>
                      First Name
                    </FormControl.Label> */}
                    <Input
                      // variant="rounded"
                      borderColor={borderColor}
                      size="lg"
                      isReadOnly={!isEditableText}
                      // style={{ color: (this.state.darkMode) ? '#ffffff' : '#000000' }}
                      onChangeText={fName => this.setState({fName})}
                      value={fName}
                      autoCapitalize={false}
                      autoCorrect={false}
                      placeholder={'First Name'}
                      // placeholderTextColor={(darkMode) ? '##666' : '#C7C7CD'}
                      InputLeftElement={<IconButton icon={<Icon as={<FontAwesomeIcon icon={['fas', 'user']} size="lg"/>} />}/>}
                      // InputLeftElement={<Icon as={<MaterialIcons name="person" />} size={5} ml="2" color="muted.400" />}
                      returnKeyType='next'
                    />
                  </FormControl>
                  <FormControl>
                    {/* <FormControl.Label>
                      {(this.getValue('LastNameFieldHasCompanyPlaceholder')) ? 'Last Name/Company' : 'Last Name'}
                    </FormControl.Label> */}
                    <Input
                      // variant="rounded"
                      borderColor={borderColor}
                      size="lg"
                      isReadOnly={!isEditableText}
                      // style={{ color: (this.state.darkMode) ? '#ffffff' : '#000000' }}
                      onChangeText={lName => this.setState({lName})}
                      value={lName}
                      autoCapitalize={false}
                      autoCorrect={false}
                      placeholder={(this.getValue('LastNameFieldHasCompanyPlaceholder')) ? 'Last Name/Company' : 'Last Name'}
                      // placeholderTextColor={(darkMode) ? '##666' : '#C7C7CD'}
                      InputLeftElement={<IconButton icon={<Icon as={<FontAwesomeIcon icon={['fas', 'user']} size="lg"/>} />}/>}
                      // InputLeftElement={<Icon as={<MaterialIcons name="person" />} size={5} ml="2" color="muted.400" />}
                      returnKeyType='next'
                    />
                  </FormControl>
                </HStack>
                {/* </div> */}
                <HStack space={3} m={6} w='95%' alignItems='center' justifyContent='space-between' mb={1} mt={1}>
                  {(this.getValue('GuestListHasCompanyName')) && (
                    <div style={{flexDirection: 'column', width: '100%'}}>
                      <FormControl>
                        {/* <FormControl.Label>
                          Company/Driver Name
                        </FormControl.Label> */}
                        <Input
                          // variant="rounded"
                          borderColor={borderColor}
                          size="lg"
                          isReadOnly={!isEditableText}
                          // style={{ color: (this.state.darkMode) ? '#ffffff' : '#000000' }}
                          onChangeText={companyName => this.setState({companyName})}
                          value={companyName}
                          autoCapitalize={false}
                          autoCorrect={false}
                          placeholder={'Company/Driver Name'}
                          // placeholderTextColor={(darkMode) ? '##666' : '#C7C7CD'}
                          InputLeftElement={<IconButton icon={<Icon as={<FontAwesomeIcon icon={['fas', 'user']} size="lg"/>} />}/>}
                          // InputLeftElement={<Icon as={<MaterialIcons name="person" />} size={5} ml="2" color="muted.400" />}
                          returnKeyType='next'
                        />
                      </FormControl>
                    </div>
                  )}

                  <div style={{flexDirection: 'column', width: '100%'}}>
                    <FormControl>
                      {/* <FormControl.Label>
                        Notes for security
                      </FormControl.Label> */}
                      <Input
                        // variant="rounded"
                        borderColor={borderColor}
                        size="lg"
                        // style={{ color: (this.state.darkMode) ? '#ffffff' : '#000000' }}
                        onChangeText={notes => this.setState({notes})}
                        value={notes}
                        autoCapitalize={false}
                        autoCorrect={false}
                        placeholder={'Notes for security'}
                        // placeholderTextColor={(darkMode) ? '##666' : '#C7C7CD'}
                        InputLeftElement={<IconButton icon={<Icon as={<FontAwesomeIcon icon={['fas', 'book']} size="lg"/>} />}/>}
                        // InputLeftElement={<Icon as={<MaterialIcons name="person" />} size={5} ml="2" color="muted.400" />}
                        returnKeyType='done'
                      />
                    </FormControl>
                  </div>
                </HStack>
              </VStack>
              <HStack space={3} m={6} w='95%' alignItems='center' justifyContent='space-between' mb={1} mt={2} zIndex={10}>
                {this.createTabs()}


                {selectedMode === 'temporary' && (
                  <HStack space={2} m={6} w='100%' mb={1} mt={4} zIndex={30}>
                    <Heading _dark={{
                      color: titleTextColor
                    }} color={titleTextColor} fontWeight="medium" size="sm" alignSelf="center">
                      Clearance Duration
                    </Heading>

                    <DateRangePicker
                      minDate={minDate}
                      maxDate={maxDate}
                      startDate={(selectedStartDate) ? moment(selectedStartDate.split('T')[0], 'YYYY-MM-DD') : null} // momentPropTypes.momentObj or null,
                      startDateId="startDate" // PropTypes.string.isRequired,
                      endDate={(selectedEndDate) ? moment(selectedEndDate.split('T')[0], 'YYYY-MM-DD') : null} // momentPropTypes.momentObj or null,
                      endDateId="endDate" // PropTypes.string.isRequired,
                      onDatesChange={({ startDate, endDate }) => {//console.log('startDate: '+startDate+' untilDate: '+endDate+ ' selectedStartDate: '+ startDate.format("YYYY-MM-DD"));
                        this.setState({selectedStartDate: startDate.format("YYYY-MM-DD"), selectedEndDate: (endDate !== null) ? endDate.format("YYYY-MM-DD") : '', startDate, endDate })}} // PropTypes.func.isRequired,
                      focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                      onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                    />

                  </HStack>
                )}
              </HStack>
              <HStack space={3} m={6} w='47%' alignItems='center' justifyContent='space-between' mb={1}>
                {(this.getValue('GuestListCustomGuestTextboxLabel1')) && (customTextbox1 !== '' && customTextbox1 != null) && (
                  <FormControl>
                    <FormControl.Label>
                      {this.getValue('GuestListCustomGuestTextboxLabel1')}
                    </FormControl.Label>
                    <Input
                      // variant="rounded"
                      borderColor={borderColor}
                      size="lg"
                      // style={{ color: (this.state.darkMode) ? '#ffffff' : '#000000' }}
                      onChangeText={customTextbox1 => this.setState({customTextbox1})}
                      value={customTextbox1}
                      autoCapitalize={false}
                      autoCorrect={false}
                      placeholder={'Enter Value'}
                      // placeholderTextColor={(darkMode) ? '##666' : '#C7C7CD'}
                      InputLeftElement={<IconButton icon={<Icon as={<FontAwesomeIcon icon={['fas', 'book']} size="lg"/>} />}/>}
                      // InputLeftElement={<Icon as={<MaterialIcons name="person" />} size={5} ml="2" color="muted.400" />}
                      returnKeyType='done'
                    />
                  </FormControl>
                )}

                {(this.getValue('GuestListCustomGuestTextboxLabel2')) && (customTextbox2 !== '' && customTextbox2 != null) && (
                  <FormControl>
                    <FormControl.Label>
                      {this.getValue('GuestListCustomGuestTextboxLabel2')}
                    </FormControl.Label>
                    <Input
                      // variant="rounded"
                      borderColor={borderColor}
                      size="lg"
                      // style={{ color: (this.state.darkMode) ? '#ffffff' : '#000000' }}
                      onChangeText={customTextbox2 => this.setState({customTextbox2})}
                      value={customTextbox2}
                      autoCapitalize={false}
                      autoCorrect={false}
                      placeholder={'Enter Value'}
                      // placeholderTextColor={(darkMode) ? '##666' : '#C7C7CD'}
                      InputLeftElement={<IconButton icon={<Icon as={<FontAwesomeIcon icon={['fas', 'book']} size="lg"/>} />}/>}
                      // InputLeftElement={<Icon as={<MaterialIcons name="person" />} size={5} ml="2" color="muted.400" />}
                      returnKeyType='done'
                    />
                  </FormControl>
                )}
              </HStack>

              {(this.getValue('GuestListCommercialCheckbox')) && (
                <HStack space={2} m={6} w='95%' mb={1} mt={1}>
                  <Checkbox shadow={2} value="test" accessibilityLabel="This is a dummy checkbox" isChecked={commercial} onChange={() => {
                    var thisThat = this;
                    this.setState({
                      commercial: !commercial,
                    }, () => {thisThat.onCommercialSelected()});
                  }}>
                    {(this.getValue('GuestListCommercialCustomCheckboxLabel') != null ) ? `${(this.getValue('GuestListCommercialCustomCheckboxLabel'))}` : 'Commercial?'}
                  </Checkbox>
                </HStack>
              )}

              {(this.getValue('GuestListHasNotificationBlock')) && (
                <HStack space={2} m={6} w='95%' mb={1} mt={1}>
                  <Checkbox shadow={2} value="test" accessibilityLabel="This is a dummy checkbox" isChecked={neverNotify} onChange={() => {
                    this.setState({
                      neverNotify: !neverNotify,
                    });
                  }}>
                    Never Notify?
                  </Checkbox>
                </HStack>
              )}

              {(this.getValue('GuestListCustomGuestCheckbox1Label') != null) && (
                <HStack space={2} m={6} w='95%' mb={1} mt={1}>
                  <Checkbox shadow={2} value="test" accessibilityLabel="This is a dummy checkbox" isChecked={customCheckbox1} onChange={() => {
                    this.setState({
                      customCheckbox1: !customCheckbox1,
                    });
                  }}>
                    Never Notify?
                  </Checkbox>
                </HStack>
              )}


              {(this.getValue('GuestListCustomGuestDropdown1Items') != null) && (
                <VStack space={2} m={6} w='95%' mb={1} mt={2} zIndex={10}>
                  {this.createTabsSegment()}
                </VStack>
              )}

              {(this.getValue('GuestListAllowBarcodeSharing')) && (this.getValue('GuestListQRDataEntryType') === '2') && (
                <Box w="35%" h='100' m={6} alignItems="center" justifyContent="center" rounded="lg" borderColor={borderColor} borderWidth="1">
                  <HStack space={2}>
                    <Heading mt='1' _dark={{
                      color: "warmGray.200"
                    }} color="coolGray.600" fontWeight="medium" size="sm">
                      Specify Number of Times Allowed?
                    </Heading>
                    <Switch size="lg" colorScheme="emerald" ml={5} isChecked={switchVal} onToggle={this.handleSwitchChange}/>
                  </HStack>
                  {(switchVal) && (
                    <Input
                      mt="3"
                      // variant="rounded"
                      borderColor={borderColor}
                      size="lg"
                      // style={{ color: (this.state.darkMode) ? '#ffffff' : '#000000' }}
                      onChangeText={guestDeviceNumberOfUses => this.setState({guestDeviceNumberOfUses})}
                      value={guestDeviceNumberOfUses}
                      autoCapitalize={false}
                      autoCorrect={false}
                      placeholder={'Enter Number'}
                      // placeholderTextColor={(darkMode) ? '##666' : '#C7C7CD'}
                      InputLeftElement={<IconButton icon={<Icon as={<FontAwesomeIcon icon={['fas', 'minus']} size="lg"/>} size={2} color="muted.400"/>} onPress={() => {this.onPressMinus()}}/>}
                      InputRightElement={<IconButton icon={<Icon as={<FontAwesomeIcon icon={['fas', 'plus']} size="lg"/>} size={2} color="muted.400"/>} onPress={() => {this.onPressPlus()}}/>}
                      returnKeyType='done'
                    />
                  )}
                </Box>
              )}
              <HStack space={3} m={6} w='95%' justifyContent='flex-end' mb={5} mt={5}>
                <Pressable
                  style={{borderRadius: 5, width: '25%', justifyContent: 'center', alignItems: 'center', background: 'linear-gradient(180deg, #0B7645 30%, #042B1C 90%)'}}
                  onPress={() => this.onSubmit()}
                  disabled={loading}
                  underlayColor={underlayColor}>
                  <HStack space={3} justifyContent="space-between" alignItems="center" p="4">
                    <Icon as={<FontAwesomeIcon icon={['fas', 'user-plus']} size="lg" color={"white"}/>}/>
                    <Heading _dark={{
                      color: "white"
                    }} color="white" fontWeight="medium" size="sm">
                      Save
                    </Heading>
                  </HStack>
                </Pressable>
                <Pressable
                  style={{borderRadius: 5, width: '25%', justifyContent: 'center', alignItems: 'center', background: '#ED1C24'}}
                  onPress={()=>{this.props.history.goBack()}}
                  disabled={loading}
                  underlayColor={underlayColor}>
                  <Heading _dark={{
                      color: "white"
                  }} color="white" fontWeight="medium" size="sm">
                    Cancel
                  </Heading>
                </Pressable>
                {/* <VStack w="25%">
                  <Button _light={{ bg: materialColor, _text: { color: "white" } }}
                    _dark={{ bg: materialColor, _text: { color: "white" } }}
                    disabled={loading}
                    // style={{borderRadius: 20}}
                    onPress={this.onSubmit}>
                    Save
                  </Button>
                  </VStack>
                  <VStack w="25%">
                  <Button _light={{ bg: "#f00", _text: { color: "white" } }}
                    _dark={{ bg: "#f00", _text: { color: "white" } }}
                    // disabled={loading}
                    // style={{borderRadius: 20}}
                    onPress={()=>{this.props.history.goBack()}}>
                    Cancel
                  </Button>
                </VStack> */}
              </HStack>
            </VStack>
          </div>
          {loading && (
            <div className={classes.loading}>
              <Spinner size="lg" />
            </div>
          )}
        </div>
      
    );
  }
}

AddEditGuest.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(AddEditGuest);
