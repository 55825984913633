import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import InputBase from '@material-ui/core/InputBase';
import {materialColor, listMainTextColor, underlayColor, searchBarBorderColor, borderBottomColor, brandColor, headerColor, borderColor, titleTextColor} from '../constants/colors';
import Snackbar from '@material-ui/core/Snackbar';
import { VStack, FormControl, Input, Button, HStack, Icon, Spinner, Flex, IconButton, Heading,Pressable } from "native-base";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {ManagePetsBanner} from '../assets/images';

const styles = {
  paper: {
    flex: 1,
    // padding: 5
  },
  fab: {
    position: 'absolute',
    bottom: 30,
    right: 40,
    zIndex: 10,
    elevation: 10
  },
  imageView: {
    //marginVertical: 5,
    //alignSelf: 'auto',
    // paddingVertical: 10,
    // paddingHorizontal: 10,
    width: 120,
    height: 120,
  },
  noImageView: {
    width: 120,
    height: 120,
    // paddingVertical: 10,
    // paddingHorizontal: 10,
    backgroundColor: borderBottomColor,
    alignSelf: 'center',
  },
  loading: {
    position: 'absolute',
    left: 420,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    //backgroundColor: '#0000ff47',
    display:'flex'
  },
};

class AddEditPet extends React.Component {
  constructor() {
    super();
    this.state = {
      isNew: true,
      petID: '',
      pet_Type: '',
      breed: '',
      pet_Color: '',
      pets_Name: '',
      reg_Num: '',
      notes: '',
      photo: '',
      loading: false,
      darkMode: false,
      textColor: '#000000',
      message: '',
      messageType: '',
      alertOpen: false
    };
  }

  componentDidMount() {
    const allData = this.props.history.location.state.data;
    // console.log('@AddEditPet.');
    // console.log(allData);

    const thisComp = this;
    if (allData) {
      thisComp.setState({
        isNew: false,
        petID: allData.petID,
        pet_Type: allData.pet_Type,
        breed: allData.breed,
        pet_Color: allData.pet_Color,
        pets_Name: allData.pets_Name,
        reg_Num: allData.reg_Num,
        notes: allData.notes,
        photo: allData.photoThumbnail,
        loading: false,
      });
    }
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({alertOpen: false});
  };

  showAlert = (message, severity) => {
    // console.log('showing alert');
    const { classes } = this.props;
    return(
      <div className={classes.fab} style={{position: 'fixed'}}>
        <Snackbar open={this.state.alertOpen} autoHideDuration={3000} onClose={this.handleClose}>
          <Alert
            variant="filled"
            severity={severity}
          >
            {message}
          </Alert>
        </Snackbar>
      </div>
    )
  }

  onSubmit=()=> {
    const {
      isNew,
      petID,
      pet_Type,
      breed,
      pet_Color,
      pets_Name,
      reg_Num,
      notes,
      photo,
      // loading,
    } = this.state;

    let saveData = {
      pet_Type,
      breed,
      pet_Color,
      pets_Name,
      reg_Num,
      notes,
      photo,
    };
    if (!isNew) {
      saveData.petID = petID;
    }
    this.saveEdit(saveData);
  }

  saveEdit = async savedata => {
    this.setState({loading: true, alertOpen: true, message: 'Sending Request...', messageType: 'info'});
    // console.log(savedata);
    const {addPetCall, editPetCall, getAllPet} = this.props;
    if (savedata.petID) {
      await editPetCall(savedata);
    } else {
      await addPetCall(savedata);
    }
    this.setState({alertOpen: true, message: 'Request processed ...', messageType: 'success'});
    await getAllPet();
    this.setState({loading: false});
    this.props.history.goBack();
  };

  handleChange = (e) => {
    // console.log(e.target.name);
    // console.log(e.target.value);
    this.setState(Object.assign(this.state, { [e.target.name]: e.target.value }))
  }


  onCancel = (e) => {
    this.setState({photo: ''});
  }

  onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onloadend = () => {
        this.setState({photo: reader.result.replace('data:image/jpeg;base64,','')});
        // console.log('photo1: ',reader.result.replace('data:image/jpeg;base64,',''));
      };
      reader.readAsDataURL(event.target.files[0]);
      // console.log('photo: ',event.target.files[0]);

      // this.setState({
      //   photo: URL.createObjectURL(event.target.files[0])
      // });
    }
  }


  render() {
    const {
      // isNew,
      // petID,
      pet_Type,
      breed,
      pet_Color,
      pets_Name,
      reg_Num,
      notes,
      photo,
      loading,
      message,
      messageType
    } = this.state;
    const { classes } = this.props;

    return (
      <div className={classes.paper}>
        <img
          style={{width: window.innerWidth, height: 230, resizeMode: 'contain', margin: 'auto', alignSelf: 'center'}}
          src={ManagePetsBanner}
          alt={'ManagePetsBanner'}
        />
        <div style={{backgroundColor: headerColor, padding: 20, marginTop: 5, borderRadius: 10, zIndex: 10, elevation: 10, marginBottom: 20}}>
          <VStack style={{borderWidth: 1, borderRadius: 5, borderColor: borderColor, backgroundColor: 'white', marginTop: 15}}>
            {this.showAlert(message, messageType)}
            {/* <VStack space={3} w='97%' alignItems='center' justifyContent='center' p={5}> */}
            <HStack space={5} m={6} w='46%' alignItems='center' justifyContent='space-between' mb={3}>
              <FormControl>
                <FormControl.Label>
                  Pet Name
                </FormControl.Label>
                <Input
                  // variant="rounded"
                  borderColor={borderColor}
                  size="lg"
                  onChangeText={pets_Name => this.setState({pets_Name})}
                  value={pets_Name}
                  autoCapitalize={false}
                  autoCorrect={false}
                  placeholder={'Pet Name'}
                  InputLeftElement={<IconButton icon={<Icon as={<FontAwesomeIcon icon={['fas', 'paw']} size="lg"/>} />}/>}
                  returnKeyType='next'
                />
              </FormControl>

              <FormControl>
                <FormControl.Label>
                  Pet Type
                </FormControl.Label>
                <Input
                  // variant="rounded"
                  borderColor={borderColor}
                  size="lg"
                  onChangeText={pet_Type => this.setState({pet_Type})}
                  value={pet_Type}
                  autoCapitalize={false}
                  autoCorrect={false}
                  placeholder={'Pet Type'}
                  InputLeftElement={<IconButton icon={<Icon as={<FontAwesomeIcon icon={['fas', 'paw']} size="lg"/>} />}/>}
                  returnKeyType='next'
                />
              </FormControl>
            </HStack>
            <HStack space={5} m={6} w='46%' alignItems='center' justifyContent='space-between' mb={0} mt={0}>
              <FormControl>
                <FormControl.Label>
                  Pet Breed
                </FormControl.Label>
                <Input
                  // variant="rounded"
                  borderColor={borderColor}
                  size="lg"
                  onChangeText={breed => this.setState({breed})}
                  value={breed}
                  autoCapitalize={false}
                  autoCorrect={false}
                  placeholder={'Pet Breed'}
                  InputLeftElement={<IconButton icon={<Icon as={<FontAwesomeIcon icon={['fas', 'paw']} size="lg"/>} />}/>}
                  returnKeyType='next'
                />
              </FormControl>

              <FormControl>
                <FormControl.Label>
                  Pet Color
                </FormControl.Label>
                <Input
                  // variant="rounded"
                  borderColor={borderColor}
                  size="lg"
                  onChangeText={pet_Color => this.setState({pet_Color})}
                  value={pet_Color}
                  autoCapitalize={false}
                  autoCorrect={false}
                  placeholder={'Pet Color'}
                  InputLeftElement={<IconButton icon={<Icon as={<FontAwesomeIcon icon={['fas', 'paw']} size="lg"/>} />}/>}
                  returnKeyType='next'
                />
              </FormControl>
            </HStack>
            <HStack space={5} m={6} w='46%' alignItems='center' justifyContent='space-between' mb={3} mt={3}>
              <FormControl>
                <FormControl.Label>
                  Reg Number
                </FormControl.Label>
                <Input
                  // variant="rounded"
                  borderColor={borderColor}
                  size="lg"
                  onChangeText={reg_Num => this.setState({reg_Num})}
                  value={reg_Num}
                  autoCapitalize={false}
                  autoCorrect={false}
                  placeholder={'Reg Number'}
                  InputLeftElement={<IconButton icon={<Icon as={<FontAwesomeIcon icon={['fas', 'registered']} size="lg"/>} />}/>}
                  returnKeyType='next'
                />
              </FormControl>

              <FormControl>
                <FormControl.Label>
                  Notes
                </FormControl.Label>
                <Input
                  // variant="rounded"
                  borderColor={borderColor}
                  size="lg"
                  onChangeText={notes => this.setState({notes})}
                  value={notes}
                  autoCapitalize={false}
                  autoCorrect={false}
                  placeholder={'Notes'}
                  InputLeftElement={<IconButton icon={<Icon as={<FontAwesomeIcon icon={['fas', 'book']} size="lg"/>} />}/>}
                  returnKeyType='done'
                />
              </FormControl>
            </HStack>
            <HStack m={6} mt={3}>
              <VStack>
                <input
                  accept="image/jpeg"
                  style={{marginLeft: 5, marginTop: 5}}
                  id="contained-button-file"
                  multiple
                  type="file"
                  onChange={this.onImageChange}
                />

                <Button mt={2} ml={1} w={100} size={"xs"} _light={{ bg: materialColor, _text: { color: "white" } }}
                  _dark={{ bg: materialColor, _text: { color: "white" } }}
                  style={{borderRadius: 5}}
                  onPress={this.onCancel}>
                  Reset
                </Button>
              </VStack>
              {photo ? (
                <img
                  className={classes.imageView}
                  id='target'
                  alt={'target'}
                  src={`data:image/jpeg;base64,${photo}`}
                />
              ) : (
                <InputBase
                  className={classes.noImageView}
                  defaultValue="No Image"
                  inputProps={{ 'aria-label': 'naked' }}
                />
              )}
            </HStack>
            <HStack space={3} m={6} mt={-10} w='95%' justifyContent='flex-end'>
              <Pressable
                style={{borderRadius: 5, width: '25%', justifyContent: 'center', alignItems: 'center', background: 'linear-gradient(180deg, #0B7645 30%, #042B1C 90%)'}}
                onPress={() => this.onSubmit()}
                disabled={loading}
                underlayColor={underlayColor}>
                <HStack space={3} justifyContent="space-between" alignItems="center" p="4">
                  <Icon as={<FontAwesomeIcon icon={['fas', 'user-plus']} size="lg" color={"white"}/>}/>
                  <Heading _dark={{
                      color: "white"
                  }} color="white" fontWeight="medium" size="sm">
                    Save
                  </Heading>
                </HStack>
              </Pressable>
              <Pressable
                style={{borderRadius: 5, width: '25%', justifyContent: 'center', alignItems: 'center', background: '#ED1C24'}}
                onPress={()=>{this.props.history.goBack()}}
                disabled={loading}
                underlayColor={underlayColor}>
                <Heading _dark={{
                  color: "white"
                }} color="white" fontWeight="medium" size="sm">
                  Cancel
                </Heading>
              </Pressable>
            </HStack>

            {/* </VStack> */}
          </VStack>
        </div>
        {loading && (
          <div className={classes.loading}>
            <Spinner size="lg" />
          </div>
        )}
      </div>

    );
  }
}

AddEditPet.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(AddEditPet);
