import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Box, VStack, HStack, Icon, Pressable, Text, Heading, AlertDialog, FormControl, Input, Button, Spinner, IconButton, Flex, NativeBaseProvider, Modal, Image, ArrowForwardIcon } from "native-base";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {setAuthHeaders} from '../api/api';
import {materialColor, mainMenuBackgroundColor, hoveredForegroundTextColor, hoverBackgroundColor, mainMenuHeaderTextColor, mainMenuHeaderSubTextColor, drawerColor, brandColor, headerColor, borderColor, arrowColor} from '../constants/colors';
import moment from "moment";
import {GuestListIcon, EventGuestIcon, RecentVisitorsIcon, NotificationSetting, ContactInfoIcon, ManagePetIcon, EmergencyContactIcon, RegisteredVehicleIcon, UpdateTravelIcon, LoginCredentialsIcon, LogoutIcon} from '../assets/images';

const iconColor = mainMenuBackgroundColor;

const styles = {
  root: {
    flexDirection: "column", alignItems: 'center', justifyContent: 'center'
    //maxWidth: 360,
    //backgroundColor: "#ffffff",
  },
  touchLinks: {
    borderWidth: 1,
    borderColor: borderColor,
    borderRadius: 5,
    paddingVertical: 8,
    paddingLeft: 10,
    marginVertical: 5,
    marginHorizontal: 10
  },
  contentHeader: {
    color: mainMenuHeaderTextColor,
    fontSize: "110%"
  },
  contentSubText: {
    color: mainMenuHeaderSubTextColor,
    fontSize: "90%"
  },
  loading: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    //backgroundColor: '#0000ff47',
    display:'flex'
  },
};

class MainMenuScreen extends React.Component {

  state = {
      textColor: mainMenuBackgroundColor,
      isVisible: false,
      isBVisible: false,
      username: '',
      password: '',
      confirmPassword: '',
      curPassword: '',
      show: false,
      // updateClicked: false,
      loading: false
      // logThisOut: false
    };

  componentDidMount() {
    //const {loginData} = this.props;
    //console.log('loginData: '+ JSON.stringify(loginData));
    setAuthHeaders(this.getValue('AuthorizationToken'));
    this.compareTime();
    this.interval = setInterval(() => this.setState({ time: Date.now() }), 1000);

    // window.history.pushState(null, document.title, window.location.href);
    // window.addEventListener('popstate', function (event){
    //     window.history.pushState(null, document.title,  window.location.href);
    // });

  }

  compareTime = async() => {
    var loginTime = await localStorage.getItem('@AbdiStore:loginTime');
    try {
          this.interval = setInterval(async () => {
              var currentTime = moment(new Date());
              var difference = currentTime.diff(loginTime, "minutes");
              //console.log('currentTime: '+currentTime+' loginTime:'+loginTime);
              //console.log('difference: '+difference);
              if (difference > 20) {
                alert('For your security your session has been timed out');
                this.logMeOut();
              }
            }, 30000);
          } catch(e) {
            console.log(e);
          }
  }

  getValue(setting) {
    const {loginData} = this.props;
    if (loginData) {
      const appOptions = loginData.appOptions;
      for (let x of appOptions) {
        if (x.setting === setting) {
          return x.value;
        }
      }
    }
  }

  goToScreen = (screen, props = {}) => {
    //const {navigation} = this.props;
    //navigation.navigate(screen, props);
    this.props.history.push({pathname: screen, state: props});
  };

  logMeOut = async (e) => {
    // console.log('logout',e);
    const { logOut } = this.props;
    await logOut();
    //console.log('logout');
    clearInterval(this.interval);
    //console.log('logout');
    localStorage.removeItem('@AbdiStore:loginTime');
    //console.log('logout');
    //this.goToScreen('LoginScreen', {logout: true})
    this.props.history.push({pathname: '/login', state:{logout: true}});
  }

  handleListItemClick = (event, index) => {
    this.setState({selectedIndex: index});
  };

  // hide show modal
  // displayModal = (show) => {
  //   if (show) {
  //     this.getLoginInfo();
  //   }
  //   this.setState({isVisible: show});
  // }

  // displayModalB = (show) => {
  //   if (show) {
  //     this.getLoginInfo();
  //   }
  //   this.setState({isBVisible: show});
  // }

  getLoginInfo = async () => {
    console.log('hii');
    const { getLoginInfo } = this.props;
    this.setState({loading: true});
    await getLoginInfo();
    console.log('loginInfo: ', this.props.loginInfo);
    // if (this.getValue('ButtonLoginCredentialsVisible') && this.getValue('LoginCredentialsModeB')) {
      this.setState({username: this.props.loginInfo.webUserName, loading: false});
    // } else {
    //   this.setState({username: this.props.loginInfo.webUserName, password: this.props.loginInfo.webPassword, loading: false});
    // }
  }

  loginPress = async () => {
    this.setState({loading: true});
    const {username, password, confirmPassword} = this.state;
    if (username && password && confirmPassword) {
      if (password === confirmPassword) {
        const { putLoginInfo } = this.props;
        let {response, error} = await putLoginInfo({WebUserName: username, WebPassword: password});
        console.log('ressssssss: ',response);
        if (response.status === 200) {
          if (this.getValue('ButtonLoginCredentialsVisible') && this.getValue('LoginCredentialsModeB')) {
            this.setState({loading: false});
          } else {
            this.setState({loading: false, isVisible: false});
            // this.refs.toast.show('Your Credentials were successfully saved. Please login again.');
            this.logMeOut();
          }
        } else {
          this.setState({loading: false});
        }
      } else {
        this.setState({loading: false});
        // this.refs.toast.show('Passwords does not matched!!!');
      }
    } else {
      this.setState({loading: false});
      // this.refs.toast.show('Please fill all the fields!');
    }
  }

  // changePressB = async () => {
  //   this.setState({loading: true});
  //   const {curPassword} = this.state;
  //   if (curPassword) {
  //     const { putPassInfo } = this.props;
  //     let {response, error} = await putPassInfo({VerbalPassword: curPassword});
  //
  //     if (response.status === 200) {
  //       this.setState({loading: false, isBVisible: false});
  //       // this.refs.toast.show('Your Credentials were successfully saved. Please login again.');
  //       this.logMeOut();
  //     } else {
  //       this.setState({loading: false});
  //     }
  //   } else {
  //     this.setState({loading: false});
  //     // this.refs.toast.show('Please fill password!');
  //   }
  // }

  render() {

    const { classes, location: { pathname } } = this.props;
    const {textColor, loading} = this.state;

    return (
      <NativeBaseProvider>
        <Flex>

          {/* <AlertDialog
            isOpen={this.state.isVisible}
            // transparent={true}
            // animationType = {"slide"}
            // visible={this.state.isVisible}
            size={'xl'}
            onClose={() => {
              this.setState({isVisible: false});
            }}>
            <AlertDialog.Content maxH="212">
              <AlertDialog.CloseButton />
              <AlertDialog.Header>Change Login Credentials</AlertDialog.Header>
              <AlertDialog.Body>
            <FormControl>
            <FormControl.Label> New User Name</FormControl.Label>
            <Input
            variant="rounded"
            size="lg"
            // style={{ color: (this.state.darkMode) ? '#ffffff' : '#000000' }}
            onChangeText={username => this.setState({username})}
            value={this.state.username}
            autoCapitalize={false}
            autoCorrect={false}
            placeholder={'Enter Username'}
            // placeholderTextColor={(darkMode) ? '##666' : '#C7C7CD'}
            InputLeftElement={<IconButton icon={<Icon as={<FontAwesomeIcon icon={['fas', 'user']} size="lg"/>} />}/>}
            returnKeyType='next'
            ref={(input) => { this.firstTextInput = input; }}
            onSubmitEditing={() => { this.secondTextInput.focus(); }}/>
            </FormControl>

            <FormControl mt="3">
            <FormControl.Label>New Password</FormControl.Label>
            <Input
            variant="rounded"
            size="lg"
            // style={{ color: (this.state.darkMode) ? '#ffffff' : '#000000' }}
            type={this.state.show ? "text" : "password"}
            InputLeftElement={<IconButton icon={<Icon as={<FontAwesomeIcon icon={['fas', "lock"]} size="lg"/>} />} />}
            InputRightElement={<IconButton icon={<Icon as={<FontAwesomeIcon icon={this.state.show ? ['fas', 'eye'] : ['fas', 'eye-slash']} size="lg"/>}
            size={1} mr="20" color="muted.400" />} onPress={() => {this.setState({show: !this.state.show})}} />}
            onChangeText={password => this.setState({password})}
            value={this.state.password}
            placeholder={'Enter Password'}
            // placeholderTextColor={(darkMode) ? '##666' : '#C7C7CD'}
            // secureTextEntry={true}
            returnKeyType='next'
            ref={(input) => { this.secondTextInput = input; }}
            onSubmitEditing={() => { this.thirdTextInput.focus(); }}/>
            </FormControl>

            <FormControl mt="3">
            <FormControl.Label>Confirm Password</FormControl.Label>
            <Input
            variant="rounded"
            size="lg"
            // style={{ color: (this.state.darkMode) ? '#ffffff' : '#000000' }}
            type={"password"}
            InputLeftElement={<IconButton icon={<Icon as={<FontAwesomeIcon icon={['fas', "lock"]} size="lg"/>} />} />}
            // InputRightElement={<Icon as={<MaterialIcons name={this.state.show ? "visibility" : "visibility-off"} />}
            //   size={5} mr="2" color="muted.400" onPress={() => {this.setState({show: !this.state.show})}} />}
            onChangeText={confirmPassword => this.setState({confirmPassword})}
            value={this.state.confirmPassword}
            placeholder={'Enter Password'}
            // placeholderTextColor={(darkMode) ? '##666' : '#C7C7CD'}
            // secureTextEntry={true}
            returnKeyType='done'
            ref={(input) => { this.thirdTextInput = input; }}
            />
            </FormControl>
            <AlertDialog.Footer>

            <Button flex="1" _light={{ bg: materialColor, _text: { color: "white" } }}
            _dark={{ bg: materialColor, _text: { color: "white" } }}
            // disabled={loading}
            style={{borderRadius: 20}}
            onPress={this.loginPress}>
            Update Login Information
            </Button>

            </AlertDialog.Footer>
              </AlertDialog.Body>
              {loading && (
            <div className={classes.loading}>
            <Spinner size="lg" />
            </div>
              )}
            </AlertDialog.Content>
            </AlertDialog>

            <Modal
            isOpen={this.state.isBVisible}
            // transparent={true}
            // animationType = {"slide"}
            // visible={this.state.isBVisible}
            // size={'xxl'}

            onClose={() => {
              this.setState({isBVisible: false});
            }}>
            <Modal.Content>
              <Modal.CloseButton />
              <Modal.Header>Change Password</Modal.Header>
              <Modal.Body>

            <div style={{padding: 10}}>
            <FormControl>
            <FormControl.Label>Registered User Name</FormControl.Label>
            <Text style={{ fontSize: 18 }}>{this.state.username}</Text>
            </FormControl>

            <FormControl mt="3">
            <FormControl.Label>New Password</FormControl.Label>
            <Input
            variant="rounded"
            size="lg"
            // style={{ color: (this.state.darkMode) ? '#ffffff' : '#000000' }}
            type={this.state.show ? "text" : "password"}
            InputLeftElement={<IconButton icon={<Icon as={<FontAwesomeIcon icon={['fas', "lock"]} size="lg"/>} />} />}
            InputRightElement={<IconButton icon={<Icon as={<FontAwesomeIcon icon={this.state.show ? ['fas', 'eye'] : ['fas', 'eye-slash']} size="lg"/>}
            size={1} mr="20" color="muted.400" />} onPress={() => {this.setState({show: !this.state.show})}} />}
            onChangeText={password => this.setState({password})}
            value={this.state.password}
            placeholder={'Enter Password'}
            // placeholderTextColor={(darkMode) ? '##666' : '#C7C7CD'}
            // secureTextEntry={true}
            returnKeyType='next'
            ref={(input) => { this.forthTextInput = input; }}
            onSubmitEditing={() => { this.fifthTextInput.focus(); }}/>
            </FormControl>

            <FormControl mt="3">
            <FormControl.Label>Confirm Password</FormControl.Label>
            <Input
            variant="rounded"
            size="lg"
            // style={{ color: (this.state.darkMode) ? '#ffffff' : '#000000' }}
            type={"password"}
            InputLeftElement={<IconButton icon={<Icon as={<FontAwesomeIcon icon={['fas', "lock"]} size="lg"/>} />} />}
            // InputRightElement={<Icon as={<MaterialIcons name={this.state.show ? "visibility" : "visibility-off"} />}
            //   size={5} mr="2" color="muted.400" onPress={() => {this.setState({show: !this.state.show})}} />}
            onChangeText={confirmPassword => this.setState({confirmPassword})}
            value={this.state.confirmPassword}
            placeholder={'Enter Password'}
            // placeholderTextColor={(darkMode) ? '##666' : '#C7C7CD'}
            // secureTextEntry={true}
            returnKeyType='done'
            ref={(input) => { this.fifthTextInput = input; }}
            />
            </FormControl>

            <VStack space={3} mt="5">
            <Button mt="2" _light={{ bg: materialColor, _text: { color: "white" } }}
            _dark={{ bg: materialColor, _text: { color: "white" } }}
            // disabled={loading}
            style={{borderRadius: 20}}
            onPress={this.loginPress}>
            Update Password
            </Button>
            </VStack>
            </div>

            <div style={{flexDirection: 'row', backgroundColor: materialColor, borderColor: materialColor, borderBottomWidth: 1, padding: 10, marginTop: 10}}>
            <Text style={{fontSize: 16, color: '#fff', fontWeight: 'bold'}}>
            Security Verification Word</Text>
            </div>
            <div style={{padding: 10}}>
            <FormControl mt="3">
            <FormControl.Label>Current Security Verification Word</FormControl.Label>
            <Input
            variant="rounded"
            size="lg"
            // style={{ color: (this.state.darkMode) ? '#ffffff' : '#000000' }}
            type={this.state.show ? "text" : "password"}
            InputLeftElement={<IconButton icon={<Icon as={<FontAwesomeIcon icon={['fas', "lock"]} size="lg"/>} />} />}
            InputRightElement={<IconButton icon={<Icon as={<FontAwesomeIcon icon={this.state.show ? ['fas', 'eye'] : ['fas', 'eye-slash']} size="lg"/>}
            size={1} mr="20" color="muted.400" />} onPress={() => {this.setState({show: !this.state.show})}} />}
            onChangeText={curPassword => this.setState({curPassword})}
            value={this.state.curPassword}
            placeholder={'Enter Password'}
            // placeholderTextColor={(darkMode) ? '##666' : '#C7C7CD'}
            // secureTextEntry={true}
            returnKeyType='next'
            // ref={(input) => { this.forthTextInput = input; }}
            />
            </FormControl>
            <VStack space={3} mt="5">
            <Button mt="2" _light={{ bg: materialColor, _text: { color: "white" } }}
            _dark={{ bg: materialColor, _text: { color: "white" } }}
            // disabled={loading}
            style={{borderRadius: 20}}
            onPress={this.changePressB}>
            Change Security Verification Word
            </Button>
            </VStack>
            </div>
            {loading && (
            <div className={classes.loading}>
            <Spinner size="lg" />
            </div>
            )}
              </Modal.Body>
            </Modal.Content>
          </Modal> */}

          <div style={{alignItems: 'center', justifyContent: 'center', paddingBottom: '3%', paddingTop: '3%', backgroundColor: headerColor}}>
            {this.getValue('CommunityLogo') && (
              <Pressable
                _hover={{bg: hoveredForegroundTextColor}}
                // bg={('/dashboard' === pathname)? hoverBackgroundColor : "transparent"}
                // style={styles.touchLinks}
                onPress={() => this.goToScreen('/dashboard')}>
                <img
                  style={{width: window.innerWidth * (10 / 100), resizeMode: 'contain', margin: 'auto', alignSelf: 'center'}}
                  src={
                  `data:image/png;base64,${this.getValue('CommunityLogo')}`
                  }
                  alt={'maindash'}
                />
              </Pressable>
            )}
          </div>

          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: headerColor, paddingBottom: '3%'}}>
            <Heading _dark={{
              color: "black"
            }} color="black" fontWeight="medium" size="sm">
              {this.getValue('UserAddress')}
            </Heading>
          </div>

          <Box flex="1">

            <div style={{height: 1, backgroundColor: mainMenuBackgroundColor}}/>

            {this.getValue('ButtonGuestListVisible') && (
              <Pressable
                _hover={{bg: hoveredForegroundTextColor}}
                bg={('/dashboard/manage-guest' === pathname)?hoverBackgroundColor:"transparent"}
                style={styles.touchLinks}
                onPress={() => this.goToScreen('/dashboard/manage-guest')}>
                <HStack alignItems="center" justifyContent="space-between">
                  <HStack space={4} alignItems="center">
                    <VStack>
                      <Image
                        source={GuestListIcon}
                        size={'xs'}
                      />
                    </VStack>
                    <VStack space={0}>
                      <Text style={styles.contentHeader}>Guest List</Text>
                      <Text style={styles.contentSubText}>Update and modify your guest list</Text>
                    </VStack>
                  </HStack>
                  <ArrowForwardIcon size="6" mr='1' color={arrowColor} />
                </HStack>
              </Pressable>
            )}

            {this.getValue('GuestListAllowMultiGuests') && (
              <Pressable
                _hover={{bg: hoveredForegroundTextColor}}
                bg={('/dashboard/add-edit-multiple-guest' === pathname)?hoverBackgroundColor:"transparent"}
                style={styles.touchLinks}
                onPress={() => this.goToScreen('/dashboard/add-edit-multiple-guest')}>
                <HStack alignItems="center" justifyContent="space-between">
                  <HStack space={4} alignItems="center">
                    <VStack>
                      <Image
                        source={EventGuestIcon}
                        size={'xs'}
                      />
                    </VStack>
                    <VStack space={0}>
                      <Text style={styles.contentHeader}>Add Event Guests</Text>
                      <Text style={styles.contentSubText}>Add Multiple People for a one-day event</Text>
                    </VStack>
                  </HStack>
                  <ArrowForwardIcon size="6" mr='1' color={arrowColor} />
                </HStack>
              </Pressable>
            )}

            {this.getValue('ButtonAuthorizedVendorsVisible') && (
              <Pressable
                _hover={{bg: hoveredForegroundTextColor}}
                bg={('/dashboard/manage-authorized-vendors' === pathname)?hoverBackgroundColor:"transparent"}
                style={styles.touchLinks}
                onPress={() => this.goToScreen('/dashboard/manage-authorized-vendors', {"message": this.getValue('AuthorizedVendorsPageMessage')})}>
                <HStack alignItems="center" justifyContent="space-between">
                  <HStack space={4} alignItems="center">
                    <VStack>
                      <Image
                        source={GuestListIcon}
                        size={'xs'}
                      />
                    </VStack>
                    <VStack space={0}>
                      <Text style={styles.contentHeader}>{this.getValue('AuthorizedVendorsButtonTitle')}</Text>
                      <Text style={styles.contentSubText}>{this.getValue('AuthorizedVendorsButtonSubtitle')}</Text>
                    </VStack>
                  </HStack>
                  <ArrowForwardIcon size="6" mr='1' color={arrowColor} />
                </HStack>
              </Pressable>
            )}

            {this.getValue('ButtonRecentVisitorsVisible') && (
              <Pressable
                _hover={{bg: hoveredForegroundTextColor}}
                bg={('/dashboard/recent-visitors' === pathname)?hoverBackgroundColor:"transparent"}
                style={styles.touchLinks}
                onPress={() => this.goToScreen('/dashboard/recent-visitors')}>
                <HStack alignItems="center" justifyContent="space-between">
                  <HStack space={4} alignItems="center">
                    <VStack>
                      <Image
                        source={RecentVisitorsIcon}
                        size={'xs'}
                      />
                    </VStack>
                    <VStack space={0}>
                      <Text style={styles.contentHeader}>Recent Visitors</Text>
                      <Text style={styles.contentSubText}>View recent visitors to this property.</Text>
                    </VStack>
                  </HStack>
                  <ArrowForwardIcon size="6" mr='1' color={arrowColor} />
                </HStack>
              </Pressable>
            )}

            {this.getValue('ButtonNotificationSettingsVisible') && this.getValue('NotificationSettingsModeB') && (
              <Pressable
                _hover={{bg: hoveredForegroundTextColor}}
                bg={('/dashboard/notification-settings-details' === pathname)?hoverBackgroundColor:"transparent"}
                style={styles.touchLinks}
                onPress={() => this.goToScreen('/dashboard/notification-settings-details')}>
                <HStack alignItems="center" justifyContent="space-between">
                  <HStack space={4} alignItems="center">
                    <VStack>
                      <Image
                        source={NotificationSetting}
                        size={'xs'}
                      />
                    </VStack>
                    <VStack space={0}>
                      <Text style={styles.contentHeader}>Notification Settings</Text>
                      <Text style={styles.contentSubText}>Change your notification settings</Text>
                    </VStack>
                  </HStack>
                  <ArrowForwardIcon size="6" mr='1' color={arrowColor} />
                </HStack>
              </Pressable>
            )}

            {this.getValue('ButtonNotificationSettingsVisible') && this.getValue('NotificationSettingsModeB') == false && (
              <Pressable
                _hover={{bg: hoveredForegroundTextColor}}
                bg={('/dashboard/notification-settings' === pathname)?hoverBackgroundColor:"transparent"}
                style={styles.touchLinks}
                onPress={() => this.goToScreen('/dashboard/notification-settings')}>
                <HStack alignItems="center" justifyContent="space-between">
                  <HStack space={4} alignItems="center">
                    <VStack>
                      <Image
                        source={NotificationSetting}
                        size={'xs'}
                      />
                    </VStack>
                    <VStack space={0}>
                      <Text style={styles.contentHeader}>Notification Settings</Text>
                      <Text style={styles.contentSubText}>Change your notification settings</Text>
                    </VStack>
                  </HStack>
                  <ArrowForwardIcon size="6" mr='1' color={arrowColor} />
                </HStack>
              </Pressable>
            )}

            {this.getValue('ButtonContactInfoVisible') && this.getValue('ContactInfoModeB') == false && (
              <Pressable
                _hover={{bg: hoveredForegroundTextColor}}
                bg={('/dashboard/contact-info' === pathname)?hoverBackgroundColor:"transparent"}
                style={styles.touchLinks}
                onPress={() => this.goToScreen('/dashboard/contact-info')}>
                <HStack alignItems="center" justifyContent="space-between">
                  <HStack space={4} alignItems="center">
                    <VStack>
                      <Image
                        source={ContactInfoIcon}
                        size={'xs'}
                      />
                    </VStack>
                    <VStack space={0}>
                      <Text style={styles.contentHeader}>Contact Info</Text>
                      <Text style={styles.contentSubText}>Update and modify your contact information</Text>
                    </VStack>
                  </HStack>
                  <ArrowForwardIcon size="6" mr='1' color={arrowColor} />
                </HStack>
              </Pressable>
            )}

            {this.getValue('ButtonContactInfoVisible') && this.getValue('ContactInfoModeB') && (
              <Pressable
                _hover={{bg: hoveredForegroundTextColor}}
                bg={('/dashboard/contact-info-details' === pathname)?hoverBackgroundColor:"transparent"}
                style={styles.touchLinks}
                onPress={() => this.goToScreen('/dashboard/contact-info-details')}>
                <HStack alignItems="center" justifyContent="space-between">
                  <HStack space={4} alignItems="center">
                    <VStack>
                      <Image
                        source={ContactInfoIcon}
                        size={'xs'}
                      />
                    </VStack>
                    <VStack space={0}>
                      <Text style={styles.contentHeader}>Contact Info</Text>
                      <Text style={styles.contentSubText}>Update and modify your contact information</Text>
                    </VStack>
                  </HStack>
                  <ArrowForwardIcon size="6" mr='1' color={arrowColor} />
                </HStack>
              </Pressable>
            )}

            {this.getValue('ButtonPetsVisible') && (
              <Pressable
                _hover={{bg: hoveredForegroundTextColor}}
                bg={('/dashboard/manage-pets' === pathname)?hoverBackgroundColor:"transparent"}
                style={styles.touchLinks}
                onPress={() => this.goToScreen('/dashboard/manage-pets')}>
                <HStack alignItems="center" justifyContent="space-between">
                  <HStack space={4} alignItems="center">
                    <VStack>
                      <Image
                        source={ManagePetIcon}
                        size={'xs'}
                      />
                    </VStack>
                    <VStack space={0}>
                      <Text style={styles.contentHeader}>Manage your pets</Text>
                      <Text style={styles.contentSubText}>Update and modify your Household pets</Text>
                    </VStack>
                  </HStack>
                  <ArrowForwardIcon size="6" mr='1' color={arrowColor} />
                </HStack>
              </Pressable>
            )}

            {this.getValue('ButtonVehiclesVisible') && (
              <Pressable
                _hover={{bg: hoveredForegroundTextColor}}
                bg={('/dashboard/registered-vehicles' === pathname)?hoverBackgroundColor:"transparent"}
                style={styles.touchLinks}
                onPress={() => this.goToScreen('/dashboard/registered-vehicles')}>
                <HStack alignItems="center" justifyContent="space-between">
                  <HStack space={4} alignItems="center">
                    <VStack>
                      <Image
                        source={RegisteredVehicleIcon}
                        size={'xs'}
                      />
                    </VStack>
                    <VStack space={0}>
                      <Text style={styles.contentHeader}>Registered Vehicles</Text>
                      <Text style={styles.contentSubText}>View your registered vehicles</Text>
                    </VStack>
                  </HStack>
                  <ArrowForwardIcon size="6" mr='1' color={arrowColor} />
                </HStack>
              </Pressable>
            )}

            {this.getValue('ButtonTravelInformationVisible') && (
              <Pressable
                _hover={{bg: hoveredForegroundTextColor}}
                bg={('/dashboard/travel-info' === pathname)?hoverBackgroundColor:"transparent"}
                style={styles.touchLinks}
                onPress={() => this.goToScreen('/dashboard/travel-info')}>
                <HStack alignItems="center" justifyContent="space-between">
                  <HStack space={4} alignItems="center">
                    <VStack>
                      <Image
                        source={UpdateTravelIcon}
                        size={'xs'}
                      />
                    </VStack>
                    <VStack space={0}>
                      <Text style={styles.contentHeader}>Update Travel Information</Text>
                      <Text style={styles.contentSubText}>Update your travel information for security</Text>
                    </VStack>
                  </HStack>
                  <ArrowForwardIcon size="6" mr='1' color={arrowColor} />
                </HStack>
              </Pressable>
            )}

            {this.getValue('ButtonEmergencyContactsVisible') && (
              <Pressable
                _hover={{bg: hoveredForegroundTextColor}}
                bg={('/dashboard/emergency-contact' === pathname)?hoverBackgroundColor:"transparent"}
                style={styles.touchLinks}
                onPress={() => this.goToScreen('/dashboard/emergency-contact')}>
                <HStack alignItems="center" justifyContent="space-between">
                  <HStack space={4} alignItems="center">
                    <VStack>
                      <Image
                        source={EmergencyContactIcon}
                        size={'xs'}
                      />
                    </VStack>
                    <VStack space={0}>
                      <Text style={styles.contentHeader}>Emergency Contacts</Text>
                      <Text style={styles.contentSubText}>Update and modify your Emergency contacts.</Text>
                    </VStack>
                  </HStack>
                  <ArrowForwardIcon size="6" mr='1' color={arrowColor} />
                </HStack>
              </Pressable>
            )}

            {/* {this.getValue('ButtonCommunityInfoVisible') && (
              <Pressable
                _hover={{bg: hoveredForegroundTextColor}}
                bg={('/dashboard/community-info' === pathname)?hoverBackgroundColor:"transparent"}
                style={styles.touchLinks}
                onPress={() => this.goToScreen('/dashboard/community-info')}>
                <HStack space={4} alignItems="center">
              <VStack w={5}>
              <Icon as={<FontAwesomeIcon icon={['fas', 'info-circle']} size="2x" color={iconColor}/>} />
              </VStack>
              <Text style={styles.contentHeader}>Community Info and News</Text>
                </HStack>
              </Pressable>
            )} */}

            {/* {this.getValue('ButtonCommunityDocumentsVisible') && (
              <Pressable
                _hover={{bg: hoveredForegroundTextColor}}
                bg={('/dashboard/documents' === pathname)?hoverBackgroundColor:"transparent"}
                style={styles.touchLinks}
                onPress={() => this.goToScreen('/dashboard/documents')}>
                <HStack space={4} alignItems="center">
              <VStack w={5}>
              <Icon as={<FontAwesomeIcon icon={['fas', 'file']} size="2x" color={iconColor}/>} />
              </VStack>
              <Text style={styles.contentHeader}>Community Documents</Text>
                </HStack>
              </Pressable>
            )} */}

            {this.getValue('ButtonLoginCredentialsVisible') && this.getValue('LoginCredentialsModeB') == false && (
              <Pressable
                _hover={{bg: hoveredForegroundTextColor}}
                bg={('/dashboard/manage-login-credentials' === pathname)?hoverBackgroundColor:"transparent"}
                style={styles.touchLinks}
                // onPress={() => this.displayModal(true)}
                onPress={() => this.goToScreen('/dashboard/manage-login-credentials')}>
                <HStack alignItems="center" justifyContent="space-between">
                  <HStack space={4} alignItems="center">
                    <VStack>
                      <Image
                        source={LoginCredentialsIcon}
                        size={'xs'}
                      />
                    </VStack>
                    <VStack space={0}>
                      <Text style={styles.contentHeader}>Login Credentials</Text>
                      <Text style={styles.contentSubText}>Manage your login credentials</Text>
                    </VStack>
                  </HStack>
                  <ArrowForwardIcon size="6" mr='1' color={arrowColor} />
                </HStack>
              </Pressable>
            )}

            {this.getValue('ButtonLoginCredentialsVisible') && this.getValue('LoginCredentialsModeB') && (
              <Pressable
                _hover={{bg: hoveredForegroundTextColor}}
                bg={('/dashboard/manage-login-credentials' === pathname)?hoverBackgroundColor:"transparent"}
                style={styles.touchLinks}
                // onPress={() => this.displayModalB(true)}
                onPress={() => this.goToScreen('/dashboard/manage-login-credentials')}>
                <HStack alignItems="center" justifyContent="space-between">
                  <HStack space={4} alignItems="center">
                    <VStack>
                      <Image
                        source={LoginCredentialsIcon}
                        size={'xs'}
                      />
                    </VStack>
                    <VStack space={0}>
                      <Text style={styles.contentHeader}>Login Credentials</Text>
                      <Text style={styles.contentSubText}>Manage your login credentials</Text>
                    </VStack>
                  </HStack>
                  <ArrowForwardIcon size="6" mr='1' color={arrowColor} />
                </HStack>
              </Pressable>
            )}

            <Pressable
              _hover={{bg: hoveredForegroundTextColor}}
              bg="transparent"
              style={styles.touchLinks}
              onPress={this.logMeOut}>
              <HStack alignItems="center" justifyContent="space-between">
                <HStack space={4} alignItems="center">
                  <VStack>
                    <Image
                      source={LogoutIcon}
                      size={'xs'}
                    />
                  </VStack>
                  <Text style={styles.contentHeader}>Log out</Text>
                </HStack>
                <ArrowForwardIcon size="6" mr='1' color={arrowColor} />
              </HStack>
            </Pressable>
          </Box>

        </Flex>
      </NativeBaseProvider>
    );
  }
}

MainMenuScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default compose( withRouter, withStyles(styles))(MainMenuScreen);
