import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { VStack, FormControl, Input, Button, Icon, Spinner, Flex, IconButton, Pressable, HStack, Heading } from "native-base";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InputMask from 'react-input-mask';
import Alert from '@material-ui/lab/Alert';
import {materialColor, listMainTextColor, underlayColor, searchBarBorderColor, borderBottomColor, brandColor, headerColor, borderColor, titleTextColor} from '../constants/colors';
import Snackbar from '@material-ui/core/Snackbar';
import {EmergencyContactsBanner} from '../assets/images';

const styles = {
  paper: {
    flex: 1,
    // padding: 5
  },
  fab: {
    position: 'absolute',
    bottom: 30,
    right: 40,
    zIndex: 10,
    elevation: 10
  },
  loading: {
    position: 'absolute',
    left: 420,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    //backgroundColor: '#0000ff47',
    display:'flex'
  },
};

class AddEditEmergencyContact extends React.Component {
  constructor() {
    super();
    this.state = {
      isNew: true,
      contactID: '',
      contact_Name: '',
      second_Phone: '',
      // contact_Name: '',
      residentid: '',
      notes: '',
      loading: false,
      message: '',
      messageType: '',
      alertOpen: false
    };
  }

  componentDidMount() {
    const allData = this.props.history.location.state.data;
    //console.log('@AddEditEmergencyContact.');
    // console.log(allData);

    const thisComp = this;
    if (allData) {
      thisComp.setState({
        isNew: false,
        contact_Name: allData.contacT_NAME,
        contact_Phone: allData.contacT_PHONE,
        contactID: allData.contactID,
        residentid: allData.residentid,
        second_Phone: allData.seconD_PHONE,
        notes: allData.notes,
      });
    }
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({alertOpen: false});
  };

  showAlert = (message, severity) => {
    // console.log('showing alert');
    const { classes } = this.props;
    return(
      <div className={classes.fab} style={{position: 'fixed'}}>
        <Snackbar open={this.state.alertOpen} autoHideDuration={3000} onClose={this.handleClose}>
          <Alert
            variant="filled"
            severity={severity}
          >
            {message}
          </Alert>
        </Snackbar>
      </div>
    )
  }

  onSubmit=()=> {
    // console.log('submit');
    const {
      isNew,
      contact_Name,
      contact_Phone,
      contactID,
      residentid,
      second_Phone,
      notes,
    } = this.state;

    if(contact_Name && contact_Phone) {
      let saveData = {
        contact_Name,
        contact_Phone,
        residentid,
        second_Phone,
        notes,
      };
      if (!isNew) {
        saveData.contactID = contactID;
      }
      this.setState({loading: true, alertOpen: true, message: 'Loading...', messageType: 'info'});

      var contact_Phone1 = contact_Phone.replaceAll('_', '');
      var second_Phone1 = second_Phone.replaceAll('_', '');

      contact_Phone1 = contact_Phone1.replace(/\s/g,'');
      second_Phone1 = second_Phone1.replace(/\s/g,'');

      if(contact_Phone1.length > 0 && contact_Phone1.length < 10){
        this.setState({message: 'Contact phone number should be of 10 digits', loading: false, messageType: 'error'});
        return;
      }

      if(second_Phone1.length > 0 && second_Phone1.length < 10){
        this.setState({message: 'Secondary phone number should be of 10 digits', loading: false, messageType: 'error'});
        return;
      }
      this.saveEdit(saveData);
    } else {
      // console.log('fill name');
      this.setState({alertOpen: true, message: 'Please fill Contact name and Contact Phone', messageType: 'error'});
      //this.showAlert('Please fill Contact name and Contact Phone', 'error');
    }
  }

  handleRes = async (response) => {
    //console.log('@handleRes response');
    //console.log(response);
    const {getAllEmergencyContacts} = this.props;
    const thisThat = this;

    if(response.data.message === 'Results OK'){
      this.setState({alertOpen: true, message: 'Request Processed!', messageType: 'success'});
      //this.showAlert('Request Processed!', 'success');
      await getAllEmergencyContacts();
      thisThat.props.history.goBack();
    }
    else{
      this.setState({alertOpen: true, message: ''+response.data.message, messageType: 'error'});
      //this.showAlert(''+response.data.message, 'error');
    }
    this.setState({loading: false});
  }

  saveEdit = async savedata => {
    // console.log(savedata);
    this.setState({loading: true, alertOpen: true, message: 'Sending Request...', messageType: 'info'});
    //this.showAlert('Sending Request...', 'info');
    const {addEmergencyCall, editEmergencyCall} = this.props;
    const thisThat = this;
    if (savedata.contactID) {
      const response = await editEmergencyCall(savedata);
      thisThat.handleRes(response);
    } else {
      const response = await addEmergencyCall(savedata);
      thisThat.handleRes(response);
    }
    //this.showAlert('done');
  };

  handleChange = (e) => {
    //console.log(e.target.name);
    //console.log(e.target.value);
    this.setState(Object.assign(this.state, { [e.target.name]: e.target.value }))
  }


  render() {
    const {
      // isNew,
      contact_Name,
      contact_Phone,
      // contactID,
      // residentid,
      second_Phone,
      notes,
      loading,
      message,
      messageType
    } = this.state;
    const { classes } = this.props;

    return (
      <div className={classes.paper}>
        <img
          style={{width: window.innerWidth, height: 230, resizeMode: 'contain', margin: 'auto', alignSelf: 'center'}}
          src={EmergencyContactsBanner}
          alt={'EmergencyContactsBanner'}
        />
        <div style={{backgroundColor: headerColor, padding: 20, marginTop: 5, borderRadius: 10, zIndex: 10, elevation: 10, marginBottom: 20}}>
          <VStack style={{borderWidth: 1, borderRadius: 5, borderColor: borderColor, backgroundColor: 'white', marginTop: 15}}>
            {this.showAlert(message, messageType)}

            {/* <VStack space={3} w='97%' alignItems='center' justifyContent='center' p={5}> */}
            <HStack space={3} m={6} w='30%' alignItems='center' justifyContent='space-between' mb={3}>
              <FormControl>
                <FormControl.Label>
                  Contact Name
                </FormControl.Label>
                <Input
                  // variant="rounded"
                  borderColor={borderColor}
                  size="lg"
                  onChangeText={contact_Name => this.setState({contact_Name})}
                  value={contact_Name}
                  autoCapitalize={false}
                  autoCorrect={false}
                  placeholder={'Contact Name'}
                  InputLeftElement={<IconButton icon={<Icon as={<FontAwesomeIcon icon={['fas', 'user']} size="lg"/>} />}/>}
                  returnKeyType='next'
                />
              </FormControl>

              <FormControl>
                <FormControl.Label>
                  Phone
                </FormControl.Label>
                <InputMask
                  mask="999 999 9999"
                  value={contact_Phone}
                  onChange={this.handleChange}
                  //className={this.props.classes.textField}
                >
                  {() => <Input
                    // variant="rounded"
                    borderColor={borderColor}
                    size="lg"
                    autoCapitalize={false}
                    autoCorrect={false}
                    placeholder={'Enter phone'}
                    InputLeftElement={<IconButton icon={<Icon as={<FontAwesomeIcon icon={['fas', 'phone']} size="lg"/>} />}/>}
                         />}
                </InputMask>
              </FormControl>
              <FormControl>
                <FormControl.Label>
                  Secondary Phone
                </FormControl.Label>
                <InputMask
                  mask="999 999 9999"
                  value={second_Phone}
                  onChange={this.handleChange}
                  //className={this.props.classes.textField}
                >
                  {() => <Input
                    // variant="rounded"
                    borderColor={borderColor}
                    size="lg"
                    autoCapitalize={false}
                    autoCorrect={false}
                    placeholder={'Enter secondary phone'}
                    InputLeftElement={<IconButton icon={<Icon as={<FontAwesomeIcon icon={['fas', 'phone']} size="lg"/>} />}/>}
                         />}
                </InputMask>
              </FormControl>
            </HStack>
            <HStack space={3} ml={6} w='60%' alignItems='center' justifyContent='space-between' mb={3}>
              <FormControl>
                <FormControl.Label>
                  Notes
                </FormControl.Label>

                <Input
                  // variant="rounded"
                  borderColor={borderColor}
                  size="lg"
                  // isReadOnly={!isEditableText}
                  onChangeText={notes => this.setState({notes})}
                  value={notes}
                  autoCapitalize={false}
                  autoCorrect={false}
                  placeholder={'Notes'}
                  InputLeftElement={<IconButton icon={<Icon as={<FontAwesomeIcon icon={['fas', 'book']} size="lg"/>} />}/>}
                  returnKeyType='next'
                />
              </FormControl>
            </HStack>

            <HStack space={3} m={6} w='95%' justifyContent='flex-end' mb={5} mt={5}>
              <Pressable
                style={{borderRadius: 5, width: '25%', justifyContent: 'center', alignItems: 'center', background: 'linear-gradient(180deg, #0B7645 30%, #042B1C 90%)'}}
                onPress={() => this.onSubmit()}
                disabled={loading}
                underlayColor={underlayColor}>
                <HStack space={3} justifyContent="space-between" alignItems="center" p="4">
                  <Icon as={<FontAwesomeIcon icon={['fas', 'user-plus']} size="lg" color={"white"}/>}/>
                  <Heading _dark={{
                      color: "white"
                  }} color="white" fontWeight="medium" size="sm">
                    Save
                  </Heading>
                </HStack>
              </Pressable>
              <Pressable
                style={{borderRadius: 5, width: '25%', justifyContent: 'center', alignItems: 'center', background: '#ED1C24'}}
                onPress={()=>{this.props.history.goBack()}}
                disabled={loading}
                underlayColor={underlayColor}>
                <Heading _dark={{
                  color: "white"
                }} color="white" fontWeight="medium" size="sm">
                  Cancel
                </Heading>
              </Pressable>
            </HStack>
            {/* </VStack> */}
          </VStack>
        </div>
        {loading && (
          <div className={classes.loading}>
            <Spinner size="lg" />
          </div>
        )}
      </div>
    );
  }
}

AddEditEmergencyContact.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(AddEditEmergencyContact);
